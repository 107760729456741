<template>
  <section>
    <navbar />
    <bread-creamb :title="details.title" />
    <section class="blog-view">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-8">
            <!-- blog content view start -->
            <div class="blog-content-view">

              <div class="blog-view-body">
                <h2 class="mt-2 mb-3 blog-view-content-heading">
                  {{ details.title }}
                </h2>
                <!-- <img
                  :src="require('@/assets/images/blog/33.jpg')"
                  alt="user"
                  class="blog-image"
                > -->
                <p class="mt-3 mb-4">
                  {{ details.body }}
                </p>
                <appointment-links></appointment-links>
              </div>

              
            </div>
            <!-- blog content view end -->
          </div>
          <div class="col-md-4 col-lg-4">
            <div class="mortgage-type-list">
              <!-- menu start -->
              <div
                v-for="(item, index) in services"
                :key="index"
                class="menu-box-list"
                :class="item.slug === details.slug ? 'is_active' : ''"
                @click="showContent(item.slug)"
              >
                <span>{{  item.title }}</span>
                <div class="icon-box">
                  <feather-icon
                    class="icon"
                    icon="ArrowRightIcon"
                  />
                </div>
              </div>
              <!-- menu end -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <app-footer />
  </section>
</template>

<script>
import Navbar from './Navbar.vue'
import AppFooter from './AppFooter.vue'
import BreadCreamb from '../site-pages/BreadCreamb.vue'
import AppointmentLinks from './AppointmentLinks.vue'

export default {
  components: {
    Navbar,
    AppFooter,
    BreadCreamb,
    AppointmentLinks,
  },
  data() {
    return {
      details: {},
      services: [],
    }
  },
  mounted() {
    this.getServices()
  },
  methods: {
    showContent(title) { 
      this.$router.push(`/mortgage/${title}`).catch(() => { })
      this.getServices()
    },
    getServices() {
      this.$http.get('/api/services')
        .then(res => {
          this.services = res.data
          this.details = res.data.filter(item => item.slug === this.$route.params.id)?.[0]
        })
        .catch(err => {
          this.showError('Failed to retrieve services')
        })
    },
  },
}
</script>

<style>

</style>
